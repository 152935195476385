<template>
  <Modal
    v-model="showModal"
    @close="confirmClose"
    @confirm="actionConfirm"
    title="Неудача"
    btnText="Оформить подписку"
    :class="['default-modal error-modal noLives-modal']"
    >
      <div class="default-modal-mainContent">
        <div class="default-modal-icon default-modal-icon--prize">
          <svg width="108" height="90" viewBox="0 0 108 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M78.8108 87.2729V87.0327C78.746 86.8384 78.661 86.6507 78.5571 86.4723C78.0722 85.4047 74.7454 84.0703 68.5768 82.4689C62.5547 80.9797 59.1885 77.825 58.4273 73.021C58.1011 70.8462 58.0444 68.4898 58.2581 66.3026C55.4165 66.8953 52.4727 66.8953 49.6311 66.3026C49.7842 68.4961 49.6707 70.8519 49.2928 73.021C48.6838 77.825 45.2725 80.9743 39.0587 82.4689C32.9691 84.0703 29.7381 85.3513 29.3322 86.4723C29.2356 86.6461 29.178 86.8369 29.163 87.0327C29.1125 87.1619 29.1125 87.3039 29.163 87.4331C29.0615 89.1465 29.569 89.9952 30.6854 89.9952H77.0346C78.371 90.0753 78.9292 89.1465 78.8108 87.2729Z" fill="white"/>
            <path d="M97.4182 18.6558C98.3923 19.257 99.1111 20.1643 99.4481 21.2179C99.6395 22.1406 99.7246 23.0802 99.7018 24.0203C99.7018 24.5007 99.6003 25.0131 99.5326 25.5416C99.2104 27.7081 98.4647 29.7988 97.3336 31.7067C95.6577 34.403 93.5131 36.8122 90.9902 38.8327C89.2631 40.374 87.395 41.7673 85.408 42.9962C84.8188 45.4027 83.8795 47.7201 82.6169 49.882L78.3033 57.4083C83.4091 54.6247 88.1989 51.3515 92.5972 47.6401C97.0006 43.9551 100.793 39.6634 103.846 34.9094C105.769 31.508 107.084 27.8285 107.737 24.0203C108.178 21.4898 108.063 18.9002 107.398 16.4139C106.777 14.651 105.596 13.1129 104.015 12.0102C102.223 10.8649 100.085 10.3028 97.9256 10.4088H87.9792L87.5563 17.695H94.0688C94.76 17.6891 95.447 17.7974 96.0987 18.0152C96.5693 18.1664 97.0139 18.3822 97.4182 18.6558Z" fill="white"/>
            <path d="M20.3166 17.6949C20.6549 24.3671 21.1342 31.0127 21.7544 37.6316C21.9825 41.9111 23.1948 46.0919 25.3068 49.8819L29.5357 57.4082C31.5656 58.5772 33.7139 59.7302 35.9637 60.8511L37.0632 61.5717C41.0551 63.6854 45.3195 65.3002 49.75 66.3757C52.5916 66.9685 55.5355 66.9685 58.3771 66.3757C62.7249 65.2942 66.9047 63.6791 70.8102 61.5717C71.256 61.3697 71.6808 61.1284 72.0789 60.8511C74.2779 59.7302 76.3755 58.5772 78.3377 57.4082L82.6512 49.8819C83.9139 47.72 84.8532 45.4026 85.4423 42.9961C85.9011 41.2369 86.1842 39.4409 86.2881 37.6316C86.8633 31.0234 87.3143 24.4045 87.6414 17.7749L87.9792 10.4088C88.0976 8.215 88.2165 5.92498 88.318 3.52296C88.4195 1.12094 86.9986 0 84.2244 0H23.6998C20.9425 0 19.5554 1.16898 19.5554 3.52296C19.5554 5.87694 19.7922 8.2149 19.8937 10.4087L20.3166 17.6949Z" fill="white"/>
            <path d="M19.9781 10.4566H10.1669C8.01169 10.3434 5.8772 10.9063 4.09414 12.0579C2.43206 13.1315 1.18497 14.6887 0.54183 16.4936C-0.0573821 18.9621 -0.160673 21.5153 0.237346 24.02C0.912259 27.8222 2.23782 31.4948 4.16181 34.8931C7.19509 39.6843 10.9892 44.0051 15.4108 47.7038C19.7998 51.4232 24.4383 54.5915 29.5208 57.4105L25.3911 49.9938C24.126 47.8164 23.1704 45.4904 22.5493 43.076C20.5499 41.8434 18.6704 40.4447 16.9332 38.8964C14.3944 36.876 12.2378 34.4607 10.556 31.7544C9.51273 29.8316 8.80984 27.7597 8.47533 25.6213C8.47533 25.0768 8.37383 24.5644 8.32309 24.02C8.2911 23.0656 8.39927 22.1117 8.64449 21.1856C8.96066 20.1485 9.65619 19.2517 10.6067 18.6554C11.0053 18.442 11.4244 18.2651 11.8585 18.127C12.5065 17.8715 13.2035 17.7461 13.9053 17.7587H20.3164L19.9781 10.4566Z" fill="white"/>
          </svg>
        </div>
        <div>
          <p class="default-modal-text">Квесты и СУПЕРИГРА доступны только для подписчиков</p>
        </div>
      </div>
      <template #customFooter>
          <div class='default-modal-footer--wrapper'>
              <p v-if="itsTrial" class="default-modal-footer">Управлять услугой можно в приложении билайн (12+): <a href="http://www.beeline.ru/login">http://www.beeline.ru/login</a></p>
          </div>
      </template>
  </Modal>
</template>


<script>
  import { useModal } from 'vue-final-modal'
  import Modal from '@/components/modals/Modal.vue'
  import { useUserStore } from '@/store/user';
  import { mapState, mapActions } from 'pinia';

export default {
  name: 'supergameTrialModal',
  components: {
    Modal,
  },
  props: {
    trialModal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(useUserStore, ['itsTrial']),
     showModal () {
            return this.trialModal
    },
  },
  methods: {
    confirmClose () {
      this.$emit('confirmClose');
    },
    actionConfirm () {
      // if (this.itsTrial) {
        this.$emit('confirmClose');
        window.location.href = '/backend/subscribe_web';
      // }
    }
  },
};
</script>
