<template>
  <!-- <div class="page-content" :class="{'active-answer': answerClicked}"> -->
  <div class="page-content normal-game" :class="{'active-answer': answerClicked, 'game-finish' : gameFinish}">
    <div class="game-header">
      <button @click="$router.back()" class="go-back">
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.26033 6.95063L10.3824 2.69325L8.12965 0.375519L0.141113 8.59441L8.12965 16.8133L10.3824 14.4956L6.26033 10.2382H19.4818V6.95063H6.26033Z" fill="#8C36BD"/>
        </svg>
      </button>
      <div class="game-header-text" v-if="currentCategory">
        <div class="game-title">{{currentCategory.title}}</div>
        <div class="game-progress">
            {{ game.solved_questions_count ? game.solved_questions_count + 1 : 1}} /{{ currentCategory.questions_count }}
        </div>
      </div>
      <div class="game-quests" v-if="currentCategory && currentCategory.is_daily_quest" :class="{completed: currentCategory.daily_answers_count==currentCategory.daily_questions_count}">
        <div class="game-quests-count" v-if="currentCategory.daily_answers_count!=currentCategory.daily_questions_count">{{currentCategory.daily_answers_count}}/{{currentCategory.daily_questions_count}}</div>
        <div class="game-quests-check" v-else></div>

      </div>
    </div>
    <div :class="['game-content'  ]" v-if="game">
      <div class="game-media-container">
        <div class="game-reward" :class="{active: checking}">+10<span></span></div>

        <div class="audio-container" v-if="game.type == 'audio'">
<!--          <audio id="audioPlayer" loop ref="audio" autoplay>-->
<!--            <source :src="getImageUrl(game.content.image_url)" type="audio/mp3" />-->
<!--          </audio>-->

          <AudioPlayer
              ref="audio"
              :option="{
                src: getImageUrl(game.content.audio_url),
              }"
              @loadedmetadata="audioStart()"
              @play="startTimer"
              @pause="pauseAudio"
          />

          <div class="video-container" v-if="game.content.video_url">
            <video  ref="video" autoplay playsinline id="videoPlayer" loop>
              <source :src="game.content.video_url" :type="videoFormat">
            </video>
          </div>

        </div>

        <div class="image-container" v-if="game.type == 'image'">
          <img :src="getImageUrl(game.content.image_url)" alt="img" v-if="imageLoad" @load="startTimer()" />
        </div>

        <div class="video-container" v-if="game.type == 'video'">
          <video ref="video" autoplay playsinline id="videoPlayer" loop>
            <source :src="game.content.video_url" :type="videoFormat">
          </video>
        </div>

      </div>
      <div class="game-question">
        <span class="game-question-text">{{game.title}}</span>
      </div>
      <div class="game-answers">
        <div class="game-timer">
          <vue-countdown class="game-timer-value" ref="countdown" v-if="counting" :time="expTime()" v-slot="{ seconds }" :transform="transformSlotProps"  @end="onCountDownEnd()">
            <span :class="{expires: seconds<=10}">{{ seconds }}</span>
          </vue-countdown>
        </div>
        <answer v-for="item in answers" :answerChoosed="answerChoosed" :item="item" @check="check" :key="item.id"></answer>
      </div>
      <teleport to="#tipBlock">
        <tips @buy="buy" :tips="tips" :buyInProgress="buyInProgress" :gameFinish="gameFinish"></tips>
      </Teleport>
      <button @click="continueGame"  ref="btnGame" :class="['btn btn-primary btn-game']">Продолжить</button>
    </div>

    <Modal
        v-model="prizeModal"
        @close="modalClose"
        @confirm="modalClose"
        :title="currentPrize?.type === 'completed_category' ? 'Категория пройдена!' : 'Поздравляем!'"
        :btnText="'Забрать'"
        class="default-modal"
    >

      <div class="default-modal-mainContent" v-if="currentPrize">
        <div class="default-modal-icon ">
          <div class="present-icon"></div>
        </div>
        <div class="default-modal-text">
          <div v-if="currentPrize?.type === 'in_a_row' " class="default-modal-text">Ваш приз за {{currentPrize.count_in_a_row}} вопросов:</div>
          <div v-else class="default-modal-text">Ваш приз:</div>

          <div class="popup-reward-list" :class="{'popup-reward-list--flex': currentPrize?.type === 'completed_category'}" >
            <div class="popup-reward-list-row" v-if="currentPrize.coins">
              <span class="icon-coin reward-list-icon"></span> {{currentPrize.coins}}
            </div>
            <div class="popup-reward-list-row" v-if="currentPrize.lives" >
              <span class="icon-lives reward-list-icon"></span> {{currentPrize.lives}}
            </div>
          </div>
          <div class="default-modal-text" v-if="newQuestionText" v-html="newQuestionText"></div>

        </div>

      </div>
    </Modal>

<!--    <Modal-->
<!--        v-model="winModal"-->
<!--        @close="modalClose"-->
<!--        @confirm="modalClose"-->
<!--        :title="'Категория пройдена!'"-->
<!--        :btnText="winPromocode ? 'Забрать' : 'Закрыть'"-->
<!--        class="default-modal"-->
<!--    >-->

<!--      <div class="default-modal-mainContent">-->
<!--        <div v-if="winPromocode" class="default-modal-icon">-->
<!--          <img class="popup-reward-icon&#45;&#45;img promo&#45;&#45;img"  :src="getImageUrl(winPromocode.offer.partner.logoUrl)"  alt="">-->
<!--        </div>-->
<!--        <div v-if="winPromocode" class="default-modal-text">-->
<!--            Ваш приз:<br>-->
<!--            {{winPromocode.offer.descriptionShort}}-->
<!--        </div>-->
<!--        <div class="default-modal-text" v-html="newQuestionText">-->
<!--        </div>-->
<!--      </div>-->
<!--    </Modal>-->


<!--    <Modal-->
<!--        v-model="promoModal"-->
<!--        @close="promoModalClose"-->
<!--        @confirm="promoModalClose"-->
<!--        :title="'Поздравляем!'"-->
<!--        :btnText="currentPromocode ? 'Забрать' : 'Закрыть'"-->
<!--        class="default-modal"-->
<!--    >-->

<!--      <div  v-if="currentPromocode" class="default-modal-mainContent">-->
<!--        <div class="default-modal-icon">-->
<!--          <img class="popup-reward-icon&#45;&#45;img promo&#45;&#45;img" :src="getImageUrl(currentPromocode.offer.partner.logoUrl)"  alt="">-->
<!--        </div>-->
<!--        <div class="default-modal-text">-->
<!--          Ваш приз:-->
<!--          <br>-->
<!--          {{currentPromocode.offer.descriptionShort}}-->
<!--        </div>-->
<!--      </div>-->
<!--    </Modal>-->

    <quest-complete-modal :questCompleteModal="finishedQuest" :answersCount="userInfo.supergame.quests_progress.answers_count" @confirmClose="closeQuestCompleteModal" :questionsCount="userInfo.supergame.quests_progress.questions_count" :itsQuest="itsQuest"></quest-complete-modal>


    <subscribe-modal @confirmClose="confirmClose" :trialModal="attemptsModal" ></subscribe-modal>

  </div>
</template>

<script>

import {RouterLink, RouterView} from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import { ref, onMounted } from 'vue'
import answer from '@/components/games/answer.vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import AudioPlayer from 'vue3-audio-player-timeline-fix'
import 'vue3-audio-player/dist/style.css'
import Modal from '@/components/modals/Modal.vue'
import questCompleteModal from '../components/modals/questCompleteModal.vue';
import subscribeModal from '@/components/modals/subscribeModal.vue';
import tips from '@/components/games/tips.vue';

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView,
    answer,
    VueCountdown,
    AudioPlayer,
    Modal,
    questCompleteModal,
    subscribeModal,
    tips,
  },
  data() {
    return {
      gameContent: null,
      outOfTime: false,
      videoFormat: 'video/mp4',
      counting: false,
      answersList: [],
      winAnswer: false,
      errorAnswer: false,
      answerClicked: false,
      gameData: {},
      track: '',
      timer: 0,
      winModal: false,
      promoModal: false,
      result: null,
      currentSeconds: 0,
      durationSeconds: 0,
      loaded: false,
      looping: false,
      playing: false,
      previousVolume: 35,
      showVolume: false,
      volume: 100,
      descriptionShort: '',
      logoUrl: '',
      autoPlay: true,
      promocodes: [],
      currentPromocode: null,
      winPromocode: null,
      gameFinish: false,
      gameFinishAnimation: false,
      finishedQuest: false,
      checking: false,
      attemptsModal: false,
      imageLoad: false,
      continuesQuest: false,
      answerChoosed: false,
      buyInProgress: false,
      questAnswers: 0,
      questQuestions: 0,
      prizeModal: false,
      prizes: [],
      currentPrize: null
    };
  },
  async created() {
    if (this.currentCategory){
      this.gameData = await this.startGame(this.currentCategory.id);
      this.answerClicked = false;
      this.setContinuesQuest();
      if (this.currentGame.type==='audio' || this.currentGame.type==='audio'){
        // const audio = document.getElementById('audioPlayer')
        // audio.setAttribute('src', this.getImageUrl(this.currentGame.content.image_url))
        // console.log(audio)
        // if (audio){
        //   audio.currentTime = 0
        //   audio.load();
        //   audio.play();
        //   // this.startTimer();
        //   audio.addEventListener('playing', () => {
        //     // audio.play();
        //     this.startTimer();
        //   })
        // }
      }

      if (this.currentGame.type==='image'){
        this.imageLoad = true;
      }

      if (this.currentGame.type==='video'){
        const video = document.getElementById('videoPlayer');
        video.load();

        video.addEventListener("playing", (event) => {
          this.startTimer();
        });

      }


    }
    else
      this.$router.push({path: `/` })

    document.addEventListener("visibilitychange", () => {
      if (this.$refs.audio)
        this.$refs.audio.pause()
      if (this.$refs.video)
        this.$refs.video.pause()
    });

  },
  computed: {
    ...mapState(useUserStore, ['userInfo','currentCategory', 'currentGame', 'tipsList', 'itsQuest', 'attemptsToSubscribePopup', 'itsTrial']),
    questEnd() {
      if (this.continuesQuest && this.userInfo.supergame.quests_progress.answers_count >= this.userInfo.supergame.quests_progress.questions_count) {
          return true
      } else {
          return false
      }
    },
    game(){
      if (this.currentGame){
        this.gameContent = this.currentGame;

        if (this.currentGame.content && this.currentGame.type==='video'){

         if (this.currentGame.content.video_url.split('.')[1] === 'webm'){
           this.videoFormat = 'video/webm'
         }
        }
      }




      return this.gameContent
    },
    answers() {
      if (this.currentGame.answers){
        this.answersList = [];

        this.currentGame.answers.forEach((el) => {
          let obj = {}
          obj.title = el.title;
          obj.id = el.id;
          obj.fail = false;
          obj.win = false;
          obj.disabled = el.disabled;

          this.answersList.push(obj);
        })


        return this.answersList;
      }
    },
    newQuestionText() {
      if (this.userInfo.newContentWillBePublishedAt && !this.result.nextQuestion) {
        const dateNewContent = new Date(this.userInfo.newContentWillBePublishedAt);
        let day = dateNewContent.getDate();
        let month = dateNewContent.getMonth()+1;
        day = day < 10 ? '0'+day : day;
        month = month < 10 ? '0'+month : month;
        let dateNewContentFormat = `${day}.${month}.${dateNewContent.getFullYear().toString().slice(-2)}`;
        // return `Возвращайтесь <span class='modal-presentInfo--yellow'>${dateNewContentFormat}</span> за новыми вопросами и призами`;
        return 'Скоро появятся новые вопросы'
      }
    },
    tips(){
      let arr = []
      if (this.currentGame.tips_state){
        this.currentGame.tips_state.forEach((el, index) => {
          let obj = {
            id: index+1,
            locked:  el !== 1 ? true : false,
            noMoney: this.userInfo.coins >= this.tipsList[index].price ? false : true,
            price: this.tipsList[index].price
          };
          arr.push(obj)
        })
      }

      return arr
    }
  },
  methods: {
    ...mapActions(useUserStore, ['getUserInfo', 'startGame', 'checkAnswer','setCurrentCategory', 'buyTip', 'updateGame','updateUserInfo', 'getPromo', 'showNoLivePopUp', 'noClosePopUpToggle', 'itsQuestToggle' ,'updateAttempts', 'getAttemps']),
    setContinuesQuest () {
      if (this.currentCategory.daily_answers_count < this.currentCategory.daily_questions_count) {
        this.continuesQuest = true;
      } else {
        this.continuesQuest = false;
      }
    },
    audioStart() {
      if (this.$refs.audio){
        this.$refs.audio.play()
      }
    },
    closeQuestCompleteModal() {
      this.finishedQuest = false;
      this.continuesQuest = false;
      this.itsQuestToggle();
    },
    getImageUrl(url) {
      return new URL(url, import.meta.url).href
    },
    async buy(tip){
      if (this.buyInProgress || this.gameFinish || this.answerChoosed) {
        return
      }
      this.buyInProgress = true;
      this.answerChoosed = true;
      const response = await this.buyTip({
        'tip_id': tip.id,
      }, this.currentCategory.id);
      if (response) {
        this.buyInProgress = false;
        setTimeout(() => this.answerChoosed = false, 300);
      }
    },
    async check(id){
      let promoModalWillShow = false;
      if (this.answerChoosed) {
        return;
      }
      this.answerChoosed = true;
      if (!this.userInfo.is_subscribed && !this.userInfo.trial_lifetime) {
          window.location.href = '/backend/subscribe_web';
          return;
      }

      if (this.gameFinish) {
        return;
      }
      // this.answerClicked = true;
      if (this.$refs.audio)
        this.$refs.audio.pause()
        if (this.$refs.video)
        this.$refs.video.pause()
      if (this.$refs.countdown)
        this.$refs.countdown.abort()

      let answerData = {
        answer_id: id == 0 ? '' : id,
        category_id: this.currentCategory.id,
        out_of_time: this.outOfTime
      }
      let response = await this.checkAnswer(answerData);
      if(response){

        Object.keys(this.answers).forEach(el => {
          if (this.answers[el].id == id){
            if(response.data.result === 'fail'){
              this.answers[el].fail = true
            }
            else{
              this.checking = true;
              this.answers[el].win = true;
            }
          }
        })

        this.updateUserInfo('coins', response.data.coins)
        this.updateUserInfo('lives', response.data.lives)
        this.updateUserInfo('categories', response.data.categories)
        this.updateUserInfo('next_live_update_at', response.data.next_live_update_at)

        if(response.data.supergame)
          this.updateUserInfo('supergame', response.data.supergame)

        this.setCurrentCategory(this.currentCategory);

        this.result = response.data;

        let HaveNextQuest = response.data.nextQuestion ? true : false;
        if (response.data.promocodes) {
          this.promocodes = response.data.promocodes;
          this.winPromocode = HaveNextQuest ? null : this.promocodes.shift();
          this.currentPromocode = this.promocodes.shift();
          if (this.currentPromocode) {
            // promoModalWillShow = true;
            setTimeout(() => {this.promoModal = true}, 700);
          }
        }

        if (response.data.prizes.length){
          this.prizes = response.data.prizes;
          this.currentPrize = this.prizes.shift();
          this.prizeModal = true;
        }


        if(response.data.nextQuestion) {
          this.gameFinish = true;
          this.gameFinishAnimation = true;
          setTimeout(() => this.gameFinishAnimation = false, 100);
          this.scrollToBtn();
        }

        if(!response.data.nextQuestion && !promoModalWillShow) {
          setTimeout(() => {this.winModal = true}, 700);
          return
        }



        if (this.itsTrial){
          this.updateAttempts();
          if (this.getAttemps() >= 5 &&  this.result.lives > 0){
            this.updateAttempts(true);
            setTimeout(() => {this.attemptsModal = true}, 800);
          }
        }

        console.log(this.currentCategory.daily_answers_count === this.currentCategory.daily_questions_count)

        if (this.currentCategory.daily_answers_count === this.currentCategory.daily_questions_count && (this.itsQuest || this.questEnd) && !promoModalWillShow) {
          setTimeout(() => {this.finishedQuest = true}, 700);
          return
      }

      }



    },
    scrollToBtn() {
      const heightGame = document.querySelector('.page-container');
      const heightGameContainer = heightGame.getBoundingClientRect().height;
      //224 (110 старое значение) - значение в transform: transalet
      // 56 - высота кнопки
      //120 - фикс, из-за того что нижний блок мешает клюку
      // const topOffset = this.$refs.btnGame.offsetTop  - heightGameContainer + 110 + 56 + 120 + 100;
      const topOffset = this.$refs.btnGame.offsetTop  - heightGameContainer + 224 + 56 + 120 + 120;
      const currentScroll = heightGame.scrollTop;
      if (heightGameContainer > this.$refs.btnGame.offsetTop && topOffset > currentScroll) {
        heightGame.scrollTo({
          top: topOffset,
          behavior: "smooth",
        });
      }
    },
    continueGame() {
      if (this.gameFinishAnimation) {
        return
      }
      if (this.gameFinish) {
        this.answerChoosed = false;
        this.answerClicked = true;
        this.buyInProgress = false;
        this.gameFinish = false;
        this.checking = false;
        this.imageLoad = false;
        if (this.result.lives > 0)
          setTimeout(() => {
            this.updateGame(this.result.nextQuestion)
            this.imageLoad = true;
            this.result = null;}, 700);
        else {
          setTimeout(() => {
            this.$router.push({path: `/` });
            this.noClosePopUpToggle(true);
            this.showNoLivePopUp()}, 700);

        }
      }
    },
    startTimer(){
      this.counting = true;
      if (this.$refs.video)
        this.$refs.video.play()

      this.imageLoad = true;
    },
    pauseAudio(){
      if (this.$refs.video)
        this.$refs.video.pause()
    },
    expTime(){
      if (this.game.time_limit){
        /* Todo: исправиль костыль с милисекундами */
        this.timer = this.game.time_limit*1010;
      }
        return this.timer;
    },
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    onCountDownEnd(){
      this.check(0)
    },
    modalClose(){
      if (this.prizes.length > 0){
        this.currentPrize = this.prizes.shift();
        return;
      }
      this.winModal = false;
      this.winPromocode = null;
      this.prizeModal = false;
      this.getUserInfo();
      if (this.currentCategory.daily_answers_count === this.currentCategory.daily_questions_count && (this.itsQuest || this.questEnd)) {
          this.finishedQuest = true;
          return
      }
      if (!this.result.nextQuestion){
        this.$router.push({path: `/` });
      }
    },
    promoModalClose() {
      this.promocode = null;
      this.promoModal = false;
      this.getUserInfo();
      if (this.promocodes.length > 0) {
        this.currentPromocode = this.promocodes.shift();
        this.promoModal = true;
        return
      }
      if(!this.result.nextQuestion){
          this.winModal = true;
          return
      }
      if (this.currentCategory.daily_answers_count === this.currentCategory.daily_questions_count && (this.itsQuest || this.questEnd)) {
          this.finishedQuest = true;
      }
    },
    confirmClose() {
      this.attemptsModal = false;
    }
  },
  beforeRouteLeave(to, from) {
    this.getUserInfo();
  },
  watch: {
    currentGame (newObj, oldCount) {
      this.gameContent = newObj;
      if (this.$refs.countdown)
        this.$refs.countdown.restart();

      this.answerClicked = false;

      const video = document.getElementById('videoPlayer');
      if(video)
        video.load();
    }
  }
};
</script>
