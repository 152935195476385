import { createApp } from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia';
import { createVfm } from 'vue-final-modal'
import VueClipboard from 'vue3-clipboard'
import vClickOutside from "click-outside-vue3"
import Vue3TouchEvents from "vue3-touch-events";
import VBodyScrollLock from 'v-body-scroll-lock'
import * as Sentry from "@sentry/vue";

import App from './App.vue'
import router from './router'
import 'vue-final-modal/style.css'
import './assets/styles/main.scss'

import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const accessToken = urlParams.get('accessToken')
if (accessToken) {
	window.location = '/backend/check-token'+queryString
}

const app = createApp(App)
const vfm = createVfm()

Sentry.init({
	app,
	dsn: process.env.VUE_APP_SENTRY,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracePropagationTargets: ["localhost", process.env.VUE_APP_SENTRY_URL],
		}),
		new Sentry.Replay(),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

app.use(createPinia())
app.use(router)
app.use(vfm)
app.use(vClickOutside)
app.use(VBodyScrollLock)
app.use(Vue3TouchEvents);
app.use(VueClipboard, {
	autoSetContainer: true,
	appendToBody: true,
})
app.use(Vue3Toasity, {
	autoClose: 2000,
	hideProgressBar: true
})

app.mount('#app')
